import React from "react";
import Typography from "@material-ui/core/Typography";
import { keep2Decimals } from "@js-shared/lib/utils/pricing";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { Grid, Box } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import PowerIcon from "@material-ui/icons/SettingsInputSvideo";
import Card from "@material-ui/core/Card";
import { minCostPlugs } from "@js-shared/lib/utils/plugs";
import Image from "next/image";
import Link from '@components/Link'
import { useTranslation } from 'react-i18next';


const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  card: {
    // width: "250px",
    boxShadow: "none",
    marginBottom: "35px",
    backgroundColor: "#f3f4f5",
    borderRadius: 6,
    marginInline: 6
  },
  media: {
    height: "200px",
    marginBottom: "10px",
  },
  paper: {
    paddingRight: "7px",
    marginRight: "8px",
    textAlign: "center",
    color: theme.palette.text.secondary,
    backgroundColor: "rgba(239, 108, 0, 0.2)",
  },
  featuredStations: {
    padding: theme.spacing(0),
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(0),
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
    backgroundColor: "rgba(239, 108, 0, 0.2)",
  },
}));

function FeaturedStations(props) {
  const classes = useStyles();
  const { t } = useTranslation();

  if (props.station) {
    return (
      <div className={classes.root}>
        <div style={{ display: "flex", paddingTop: "20px" }}>
          <Link href={`stations-map/${props.station.slug}`} naked>
            <Card className={classes.card}>
                <Image
                  src={props.station.image}
                  className="rounded-full"
                  width={250}
                  height={200}
                  title={props.station.name}
                  alt={props.station.name}
                  objectFit="cover"
                  quality={50}
                />

              {/* Displaying the First line: NAME + PRICING OF THE STATION*/}
              <div>
                <Typography
                  variant="h6"
                  style={{ color: "black", fontWeight: "bold" }}
                >
                  {props.station.name}
                </Typography>
                {props.station.plugs.length !== 0 ? (
                  <>
                    {keep2Decimals(parseFloat(minCostPlugs(props.station))) !==
                    0 ? (
                      <Typography variant="body2" color="textSecondary">
                        {t("base_price_string", {
                          base_price: keep2Decimals(
                            parseFloat(minCostPlugs(props.station))
                          ),
                        })}
                      </Typography>
                    ) : (
                      <Typography variant="body2" color="textSecondary">
                        {t("Δωρεάν")}
                      </Typography>
                    )}
                  </>
                ) : (
                  <Typography variant="body2" color="textSecondary">
                    {t("Δεν έχει πρίζες")}
                  </Typography>
                )}
              </div>

              {/* Displaying the available plugs!!! */}
              <Box style={{ display: "flex", flexDirection: "row" }}>
                <Grid
                  container
                  spacing={3}
                  style={{ marginLeft: "0px", marginBottom: "14px" }}
                >
                  {props.unique_plugs.map((unique_plug, index) => (
                    <div key={index}>
                      <Grid item xs>
                        <Paper
                          className={classes.featuredStations}
                          elevation={0}
                        >
                          <Typography
                            variant="subtitle2"
                            color="primary"
                            align="left"
                          >
                            <IconButton
                              disabled
                              className={classes.iconButton}
                              aria-label="menu"
                              style={{ padding: "2px" }}
                            >
                              <PowerIcon style={{ fontSize: 20 }}  color="primary" />
                            </IconButton>
                            {unique_plug.power} kW
                          </Typography>
                        </Paper>
                      </Grid>
                    </div>
                  ))}
                </Grid>
              </Box>
            </Card>
          </Link>
        </div>
      </div>
    );
  } else return null;
}

const mapStateToProps = (state) => {
  return {
    //fetched_stations: state.stations.fetched_stations,
  };
};

export default connect(mapStateToProps)(FeaturedStations);
